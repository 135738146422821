<template>
  <main class="flex items-center justify-between my-6" v-if="from">
    <div class="flex flex-col items-center">
      <!-- Help text -->
      <span class="text-base font-bold text-[#132013CC]">
        Showing Results: <span class="text-[#132013CC] font-semibold">{{ from }}</span> -
        <span class="text-[#132013CC] font-semibold">{{ to }}</span> of
        <span class="text-[#132013CC] font-semibold">{{ total }}</span>
      </span>
    </div>

    <nav aria-label="Page navigation example">
      <ul class="inline-flex items-center -space-x-px">
        <li>
          <a
            @click="paginate(prev)"
            class="block px-3 py-2 ml-0 leading-tight text-brand-black bg-white rounded-l-lg hover:bg-brand-black hover:text-white"
          >
            <span class="sr-only">Previous</span>
            <svg aria-hidden="true" class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </li>
        <li v-for="link in pages.slice(1, -1)" :key="link">
          <a
            @click="paginate(link.url)"
            :class="current == link.label ? 'bg-brand-black rounded-lg text-white' : 'bg-white text-brand-black'"
            class="px-3 py-2 mx-1 leading-tight text-base hover:rounded-lg hover:text-white hover:bg-brand-black cursor-pointer"
            >{{ link.label }}</a
          >
        </li>
        <li>
          <a
            @click="paginate(next)"
            class="block px-3 py-2 ml-0 leading-tight text-brand-black bg-white rounded-r-lg hover:bg-brand-black hover:text-white"
          >
            <span class="sr-only">Next</span>
            <svg aria-hidden="true" class="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </main>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps(['from', 'to', 'total', 'pages', 'current', 'next', 'prev']);
const emit = defineEmits(['move']);

const paginate = (url) => {
  if (!url) return;
  const link = new URL(url);
  emit('move', { url, params: link.searchParams });
};
</script>
